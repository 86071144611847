<template>
    <ion-page>
        <ion-card>
            <ion-card-header class="ion-text-center">
                <img src="assets/logo.png" alt="Dealer Incident Reporting" />
                <ion-card-title>Logging Out. . .</ion-card-title>
            </ion-card-header>

            <ion-card-content>
                <ion-spinner v-if="exiting" color="primary"></ion-spinner>
            </ion-card-content>
        </ion-card>
    </ion-page>
</template>

<script>
import { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonSpinner } from '@ionic/vue';

export default {
    name: 'Logout',
    components: { IonPage, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonSpinner },
    data(){
        return {
            exiting: true,
        };
    },
    mounted(){
        this.$store.commit('unauthenticate');
        this.exiting = false;
        this.$router.push({ name: 'login' });
    },
};
</script>
